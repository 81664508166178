.testimonial-section .swiper-slide {
    padding-bottom: 20px;
}

.testimonial-section h3 {
    text-align: center;
}

@media screen and (max-width: 992px) {
    .testimonial-section h2 {
      margin-bottom: 0px;
    }
  }