.navbar {
    width: 100%;
    padding: 10px 0;
    box-sizing: border-box;
    margin-bottom: 0px;
  }

.navbar li {
  padding-left: 0px;
  padding-right: 0px;
}

.navbar ul {
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.navbar .logo {
  font-weight: bold;
  color: var(--heading-color);
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  margin-right: auto;
}
  
.navbar .logo img {
  margin-right: 10px;
  filter: invert(10%);
  width: 160px;
  height: 100px;
  margin-top: -8px;
}

.navbar a {
  color: var(--navbar-text-color);
  text-decoration: none;
  margin-right: 0px;
  padding: 10px 20px 10px 20px;
}

/* LOGIN BUTTON */ 
.navbar .login-button {
  color: var(--primary-color);
}

.navbar .login-button:hover {
  border: 1px solid var(--white-color);
}

.navbar .login-button:focus {
  color: var(--primary-color);
}

.navbar .login-button:focus:hover {
  color: var(--white-color);
}

/* LOGOUT BUTTON */ 

.navbar button:hover {
  color: var(--white-color);
}

.navbar button:focus {
  color: var(--primary-color);
}

.navbar button:focus:hover {
  color: var(--white-color);
}

/* NAVBAR FOR TABLET  */

@media screen and (max-width: 992px) {

  .navbar .logo img {
    width: 160px;
    height: 100px;
  }

  .navbar a,
  .navbar a:focus {
    font-size: 12px;
    padding: 10px 20px 10px 20px;
  }

  .navbar .btn-react {
    font-size: 12px;
  }

  .mobile-nav-toggle{
    margin-left: 20px;
  }

  .navbar .logo {
    margin-right: 0;
  }

  .navbar-mobile {
    display: flex;
    flex-direction: row-reverse; 
  }
}

/* NAVBAR FOR MOBILE  */

@media screen and (max-width: 600px) {

  .navbar .logo {
    margin-top: -20px;
    margin-bottom: -15px;
    padding-top: 5px;
  }

  .navbar .logo img {
    width: 160px;
    height: 100px;
    margin-top: 0px;
  }

  .navbar a,
  .navbar a:focus {
    font-size: 10px;
    padding: 10px 10px 10px 10px;
  }

  .navbar .btn-react {
    font-size: 10px;
    padding: 10px 10px 10px 10px;
  }
}