.center-text {
    display: flex;
    justify-content: center;
}

.jobs-box {
    height: 200px;
    box-shadow: none;
    display: flex;
    justify-content: center;
  }