/* SWIPER CSS STYLES */
.swiper-container {
  width: 70%;
  padding-top: 50px;
  padding-bottom: 50px;
}

@media screen and (max-width: 992px) {
  .swiper-container {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 110%;
  padding: 0px;
}

@media screen and (max-width: 992px) {
  .swiper-slide {
    padding: 0px 12px;
  }
  .newsfeed h2 {
    margin-bottom: 0px;
  }
}

.swiper-slide img {
  display: block;
  width: 100%;
}

.swiper-pagination .swiper-pagination-bullet  {
  width: 15px;
  height: 15px;
  border: 2px solid var(--white-color);
}